import React from 'react';
import styled from 'styled-components';

const PolicyOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const PolicyContainer = styled.div`
    background-color: #1a1a1a;
    color: #fff;
    padding: 2rem;
    border-radius: 10px;
    max-width: 800px;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
`;

const PrivacyPolicy = ({onClose}) => (
    <PolicyOverlay>
        <PolicyContainer>
            <CloseButton onClick={onClose}>&times;</CloseButton>
            <h1>Informativa sulla Privacy</h1>
            <p>Ultimo aggiornamento: {new Date().toLocaleDateString()}</p>
            <h2>1. Introduzione</h2>
            <p>Epic Echoes ("noi", "nostro" o "ci") rispetta la tua privacy e si impegna a proteggere i tuoi dati
                personali.</p>
            <h2>2. I dati che raccogliamo</h2>
            <p>Raccogliamo ed elaboriamo i seguenti dati:</p>
            <ul>
                <li>Dati di utilizzo: Informazioni su come utilizzi il nostro sito web.</li>
                <li>Dati dei cookie: Dati memorizzati nei cookie sul tuo dispositivo.</li>
            </ul>
            <h2>3. Come utilizziamo i tuoi dati</h2>
            <p>Utilizziamo i tuoi dati per:</p>
            <ul>
                <li>Analizzare l'utilizzo del sito web per migliorare l'esperienza utente.</li>
                <li>Personalizzare contenuti e pubblicità.</li>
            </ul>
            <h2>4. Google Analytics</h2>
            <p>Utilizziamo Google Analytics per analizzare l'uso del nostro sito web. Google Analytics raccoglie
                informazioni sull'utilizzo del sito web tramite cookie. Le informazioni raccolte relative al nostro sito
                web vengono utilizzate per creare report sull'utilizzo del nostro sito web.</p>
            <h2>5. I tuoi diritti</h2>
            <p>Hai il diritto di:</p>
            <ul>
                <li>Accedere ai tuoi dati personali</li>
                <li>Rettificare i tuoi dati personali</li>
                <li>Cancellare i tuoi dati personali</li>
                <li>Limitare il trattamento dei tuoi dati personali</li>
                <li>Opporti al trattamento dei tuoi dati personali</li>
                <li>Portabilità dei dati</li>
            </ul>
            <h2>6. Modifiche a questa politica</h2>
            <p>Possiamo aggiornare questa politica sulla privacy di tanto in tanto. Ti informeremo di eventuali
                modifiche pubblicando la nuova politica sulla privacy su questa pagina.</p>
            <h2>7. Contattaci</h2>
            <p>Per qualsiasi domanda su questa politica sulla privacy, contattaci all'indirizzo:
                aiechoesmusic@proton.me</p>
        </PolicyContainer>
    </PolicyOverlay>
);

export default PrivacyPolicy;