import {createGlobalStyle} from 'styled-components';

const GlobalStyles = createGlobalStyle`
    :root {
        --bg-color: #000000;
        --text-color: #FFFFFF;
        --neon-1: #FF00FF;
        --neon-2: #00FFFF;
        --neon-3: #FFFF00;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body {
        font-family: 'Roboto Mono', monospace;
        background-color: var(--bg-color);
        color: var(--text-color);
        line-height: 1.6;
        overflow-x: hidden;
        cursor: none;
    }

    a, button, [role="button"] {
        cursor: none;
    }

    .custom-cursor {
        width: 20px;
        height: 20px;
        border: 2px solid var(--neon-2);
        border-radius: 50%;
        position: fixed;
        pointer-events: none;
        z-index: 9999;
        transition: all 0.1s ease;
        transform: translate(-50%, -50%);
    }

    .custom-cursor::after {
        content: '';
        width: 4px;
        height: 4px;
        background-color: var(--neon-2);
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .custom-cursor.clickable {
        width: 40px;
        height: 40px;
        border-color: var(--neon-1);
    }

    .container {
        max-width: 1400px;
        margin: 0 auto;
        padding: 0 20px;
    }

    @keyframes gridMove {
        0% {
            background-position: 0 0;
        }
        100% {
            background-position: 100px 100px;
        }
    }
`;

export default GlobalStyles;