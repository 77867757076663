import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 0;
    z-index: 1000;
    transition: background-color 0.3s ease;
    background-color: ${props => props.$isScrolled ? 'rgba(5, 5, 5, 0.9)' : 'transparent'};
    backdrop-filter: ${props => props.$isScrolled ? 'blur(10px)' : 'none'};
`;

const Nav = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;
`;

const Logo = styled.a`
    font-family: 'Orbitron', sans-serif;
    font-size: 1.5rem;
    font-weight: 800;
    color: #00FFFF;
    text-decoration: none;
`;

const NavLinks = styled.ul`
    display: flex;
    list-style: none;
`;

const NavItem = styled.li`
    margin-left: 30px;
`;

const NavLink = styled.a`
    color: #FFFFFF;
    text-decoration: none;
    font-weight: 400;
    transition: color 0.3s ease;

    &:hover {
        color: #FF00FF;
    }
`;

function Header() {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleClick = (e, targetId) => {
        e.preventDefault();
        const target = document.getElementById(targetId);
        if (target) {
            target.scrollIntoView({behavior: 'smooth'});
        }
    };

    return (
        <HeaderContainer $isScrolled={isScrolled}>
            <Nav>
                <Logo href="#" onClick={(e) => handleClick(e, 'home')}>Epic Echoes</Logo>
                <NavLinks>
                    <NavItem><NavLink href="#home" onClick={(e) => handleClick(e, 'home')}>Home</NavLink></NavItem>
                    <NavItem><NavLink href="#experience"
                                      onClick={(e) => handleClick(e, 'experience')}>Esperienza</NavLink></NavItem>
                    <NavItem><NavLink href="#album" onClick={(e) => handleClick(e, 'album')}>Album</NavLink></NavItem>
                </NavLinks>
            </Nav>
        </HeaderContainer>
    );
}

export default Header;