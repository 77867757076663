import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
    background-color: rgba(0, 0, 0, 0.8);
    color: #FFFFFF;
    text-align: center;
    padding: 20px 0;
`;

const SocialLinks = styled.div`
    margin-top: 20px;
`;

const SocialLink = styled.a`
    color: #FFFFFF;
    font-size: 1.5rem;
    margin: 0 10px;
    transition: color 0.3s ease;

    &:hover {
        color: #00FFFF;
    }
`;

const ContactLink = styled.a`
    color: #00FFFF;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;

    &:hover {
        color: #FF00FF;
    }
`;

function Footer() {
    return (
        <FooterContainer>
            <div className="container">
                <p>&copy; 2024 Epic Echoes. Tutti i diritti riservati.</p>
                <p>
                    Contattaci su{' '}
                    <ContactLink
                        href="https://www.instagram.com/epic_echoes_music"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Instagram
                    </ContactLink>
                  ,{' '}
                    <ContactLink
                        href="https://www.tiktok.com/@epicechoesmusic"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        TikTok
                    </ContactLink>
                  {' '}o via{' '}
                  <ContactLink
                      href="mailto:contact@epicechoesmusic.com"
                  >
                    email
                    </ContactLink>
                </p>
                <SocialLinks>
                    <SocialLink href="https://www.instagram.com/epic_echoes_music" target="_blank"
                                rel="noopener noreferrer" aria-label="Instagram">
                        <i className="fab fa-instagram"></i>
                    </SocialLink>
                  <SocialLink href="#referrer" aria-label="YouTube">
                        <i className="fab fa-youtube"></i>
                    </SocialLink>
                  <SocialLink href="#referrer" aria-label="Spotify">
                        <i className="fab fa-spotify"></i>
                    </SocialLink>
                    <SocialLink href="https://www.tiktok.com/@epic_echoes_music" target="_blank"
                                rel="noopener noreferrer" aria-label="TikTok">
                        <i className="fab fa-tiktok"></i>
                    </SocialLink>
                  <SocialLink href="mailto:contact@epicechoesmusic.com" aria-label="Email">
                    <i className="fas fa-envelope"></i>
                    </SocialLink>
                </SocialLinks>
            </div>
        </FooterContainer>
    );
}

export default Footer;