import React, {useEffect, useRef, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import {getRandomVerse} from '../data/poetryData';

const teslaEffect = keyframes`
    0%, 100% {
        opacity: 0.3;
    }
    50% {
        opacity: 0.7;
    }
`;

const ExperienceSection = styled.section`
    padding: 100px 0;
    position: relative;
`;

const SectionTitle = styled.h2`
    font-family: 'Orbitron', sans-serif;
    font-size: 3rem;
    text-align: center;
    color: #FFFF00;
    text-transform: uppercase;

    @media (max-width: 768px) {
        font-size: 2rem;
    }
`;

const AIPoetryVisualizer = styled.div`
    width: 100%;
    height: 400px;
    background-color: rgba(255, 255, 255, 0.05);
    position: relative;
    overflow: hidden;

    @media (max-width: 768px) {
        height: 300px;
    }
`;

const TeslaBackground = styled.canvas`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const PoetryLines = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    text-align: center;
    white-space: normal;
    overflow: hidden;
    z-index: 1;
    width: 90%;
    padding: 0 10px;

    @media (max-width: 768px) {
        font-size: 1rem;
    }
`;

const PoetryLine = styled.div`
    opacity: 0;
    animation: ${teslaEffect} 3s ease-in-out infinite;
    margin-bottom: 10px;
`;

const ContentBox = styled.div`
    background-color: rgba(255, 255, 255, 0.1);
    padding: 30px;
    margin-bottom: 30px;
    border-left: 4px solid #00FFFF;

    @media (max-width: 768px) {
        padding: 20px;
    }
`;

const ContentTitle = styled.h3`
    color: #00FFFF;
    margin-bottom: 15px;
    font-family: 'Orbitron', sans-serif;

    @media (max-width: 768px) {
        font-size: 1.2rem;
    }
`;

const ExpandButton = styled.button`
    background: none;
    border: none;
    color: #00FFFF;
    cursor: pointer;
    font-family: 'Orbitron', sans-serif;
    font-size: 1rem;
    margin-top: 15px;
    text-decoration: underline;
    transition: color 0.3s ease;

    &:hover {
        color: #FFFF00;
    }
`;

const Manifesto = styled.div`
    margin-top: 20px;
    max-height: ${props => props.expanded ? '1000px' : '0'};
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
`;

function Experience() {
    const [poetryLines, setPoetryLines] = useState([]);
    const [manifestoExpanded, setManifestoExpanded] = useState(false);
    const canvasRef = useRef(null);

    useEffect(() => {
        const generateLines = () => {
            const lines = [getRandomVerse(), getRandomVerse(), getRandomVerse()];
            setPoetryLines(lines);
        };

        generateLines();
        const interval = setInterval(generateLines, 12000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        let animationFrameId;

        const resizeCanvas = () => {
            canvas.width = canvas.offsetWidth;
            canvas.height = canvas.offsetHeight;
        };

        resizeCanvas();
        window.addEventListener('resize', resizeCanvas);

        const particles = [];
        const particleCount = 50;

        for (let i = 0; i < particleCount; i++) {
            particles.push({
                x: Math.random() * canvas.width,
                y: Math.random() * canvas.height,
                radius: Math.random() * 2 + 1,
                color: `rgba(0, 255, 255, ${Math.random() * 0.5 + 0.5})`,
                velocity: {
                    x: (Math.random() - 0.5) * 2,
                    y: (Math.random() - 0.5) * 2
                }
            });
        }

        const drawParticles = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            particles.forEach(particle => {
                ctx.beginPath();
                ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
                ctx.fillStyle = particle.color;
                ctx.fill();

                particle.x += particle.velocity.x;
                particle.y += particle.velocity.y;

                if (particle.x < 0 || particle.x > canvas.width) particle.velocity.x *= -1;
                if (particle.y < 0 || particle.y > canvas.height) particle.velocity.y *= -1;
            });

            animationFrameId = requestAnimationFrame(drawParticles);
        };

        drawParticles();

        return () => {
            window.removeEventListener('resize', resizeCanvas);
            cancelAnimationFrame(animationFrameId);
        };
    }, []);

    return (
        <ExperienceSection id="experience">
            <div className="container">
                <SectionTitle>Fusione Poetica AI</SectionTitle>
                <AIPoetryVisualizer>
                    <TeslaBackground ref={canvasRef}/>
                    <PoetryLines>
                        {poetryLines.map((line, index) => (
                            <PoetryLine key={index} style={{animationDelay: `${index * 1.3}s`}}>{line}</PoetryLine>
                        ))}
                    </PoetryLines>
                </AIPoetryVisualizer>
                <ContentBox>
                    <ContentTitle>La Rivoluzione Poetica</ContentTitle>
                    <p>Epic Echoes fonde l'immortale bellezza della poesia italiana classica con l'avanguardia
                        dell'intelligenza artificiale e i ritmi pulsanti della musica contemporanea. Il nostro
                        innovativo approccio analizza secoli di versi, dalla Divina Commedia di Dante alle rime di
                        D'Annunzio, ricreandoli in forme sonore mai udite prima.</p>

                    <ExpandButton onClick={() => setManifestoExpanded(!manifestoExpanded)}>
                        {manifestoExpanded ? "Mostra meno" : "Leggi il nostro Manifesto"}
                    </ExpandButton>

                    <Manifesto expanded={manifestoExpanded}>
                        <h4>Manifesto di Epic Echoes</h4>
                        <ol>
                            <li>Rivoluzione Culturale: Creiamo un ponte tra la tradizione poetica e l'innovazione
                                tecnologica.
                            </li>
                            <li>Intelligenza Artificiale come Musa: L'IA è il nostro collaboratore creativo, non un
                                sostituto.
                            </li>
                            <li>Accessibilità della Poesia: Rendiamo i versi classici rilevanti per le nuove
                                generazioni.
                            </li>
                            <li>Sperimentazione Continua: Spingiamo i confini della creazione musicale e poetica.</li>
                            <li>Rispetto per la Tradizione: Innoviamo preservando l'essenza della poesia italiana.</li>
                        </ol>
                    </Manifesto>
                </ContentBox>
            </div>
        </ExperienceSection>
    );
}

export default Experience;
