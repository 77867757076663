export const poetryVerses = [
    "Nel mezzo del cammin di nostra vita, mi ritrovai per una selva oscura, ché la diritta via era smarrita.", // Inferno, Canto I
    "A Zacinto, che te specchi nell'onde del greco mar da cui vergine nacque Venere.", // A Zacinto
    "Il sabato del villaggio, il giorno più gradito.", // Il Sabato del Villaggio
    "Sempre caro mi fu quest'ermo colle, e questa siepe, che da tanta parte dell'ultimo orizzonte il guardo esclude.", // L'infinito
    "La sera al dì di festa, in suo gorgo m'avvolge.", // La Sera al Dì di Festa
    "Il passero solitario per l'aria azzurra va, né mai il romito a l'ozio siede.", // Il Passero Solitario
    "Per me si va ne la città dolente, per me si va ne l'etterno dolore.", // Inferno, Canto III
    "Verrà la morte e avrà i tuoi occhi, sarà come smettere un vizio.", // Verrà la Morte e Avrà i Tuoi Occhi
    "Forse perché della fatal quiete tu sei l'immago, a me sì cara vieni.", // Alla Sera
    "Tanto gentile e tanto onesta pare la donna mia, quand'ella altrui saluta, ch'ogne lingua devèn tremando muta.", // Tanto Gentile e Tanto Onesta Pare
    "San Martino spazza il ciel di maggio, e X Agosto lacrime versa.", // X Agosto
    "Silvia, rimembri ancora quel tempo della tua vita mortale, quando beltà splendea.", // A Silvia
    "Che fai tu, luna, in ciel? dimmi, che fai, silenziosa luna?", // Canto Notturno di un Pastore Errante dell'Asia
    "Dei Sepolcri, il canto triste, e l'urne de' forti, di luce son sparte.", // Dei Sepolcri
    "E piove su le tue ciglia, Ermione, piove su le tue ciglia nere.", // La Pioggia nel Pineto
    "Cantami, o Diva, del pelìde Achille l'ira funesta che infiniti addusse lutti agli Achei.", // Iliade, Proemio
];


function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

export function getRandomVerse() {
    const shuffledVerses = [...poetryVerses];
    shuffleArray(shuffledVerses);
    return shuffledVerses[Math.floor(Math.random() * shuffledVerses.length)];
}