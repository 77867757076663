import React, {useEffect, useRef, useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import ReactGA from 'react-ga4';
import Header from './components/Header';
import Hero from './components/Hero';
import Experience from './components/Experience';
import Album from './components/Album';
import Footer from './components/Footer';
import GlobalStyles from './styles/GlobalStyles';
import PrivacyPolicy from './components/PrivacyPolicy';
import CookieConsent, {Cookies} from "react-cookie-consent";
import logo from './images/logo.jpg';

const MEASUREMENT_ID = 'G-3DEVSEKNXK';
const STREAM_ID = '8416048478';

export const initGA = () => {
    ReactGA.initialize(MEASUREMENT_ID, {
        gaOptions: {
            streamId: STREAM_ID
        },
        gtagOptions: {
            send_page_view: false // This prevents duplicate page views
        }
    });
};

export const logPageView = () => {
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        stream_id: STREAM_ID
    });
};

export const logEvent = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label,
        stream_id: STREAM_ID
    });
};
function App() {
  const cursorRef = useRef(null);
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [hasConsent, setHasConsent] = useState(false);

  useEffect(() => {
      const consent = Cookies.get('CookieConsent');
      if (consent === 'true') {
          initGA();
          logPageView();
          setHasConsent(true);
      }

    const cursor = cursorRef.current;
    const moveCursor = (e) => {
      cursor.style.left = `${e.clientX}px`;
      cursor.style.top = `${e.clientY}px`;
    };
    const handleMouseOver = (e) => {
      if (e.target.closest('a, button, [role="button"]')) {
        cursor.classList.add('clickable');
      }
    };
    const handleMouseOut = () => {
      cursor.classList.remove('clickable');
    };
    document.addEventListener('mousemove', moveCursor);
    document.addEventListener('mouseover', handleMouseOver);
    document.addEventListener('mouseout', handleMouseOut);
    return () => {
      document.removeEventListener('mousemove', moveCursor);
      document.removeEventListener('mouseover', handleMouseOver);
      document.removeEventListener('mouseout', handleMouseOut);
    };
  }, [hasConsent]);

    const handleAccept = () => {
        initGA();
        logPageView();
        setHasConsent(true);
    };

    const schema = {
        "@context": "https://schema.org",
        "@type": "MusicGroup",
        "name": "Epic Echoes",
        "url": "https://www.epicechoesmusic.com",
        "logo": logo,
        "sameAs": [
            "https://www.instagram.com/",
            "https://open.spotify.com/artist/your-spotify-id", // Replace with actual Spotify ID
        ],
        "genre": ["Trap", "Hip-Hop", "Classical Poetry"],
        "description": "Epic Echoes fuses classical Italian poetry with modern trap and hip-hop beats, creating a unique auditory experience that bridges centuries of artistic expression."
    };

  return (
      <HelmetProvider>
          <Router>
              <GlobalStyles/>
              <div className="App">
                  <Helmet>
                      <title>Epic Echoes | Rivoluzione Poetica AI</title>
                      <meta name="description"
                            content="Epic Echoes fonde poesia italiana classica con AI e musica moderna. Scopri la nostra innovativa miscela di versi senza tempo e ritmi futuristici."/>
                      <meta name="keywords"
                            content="Epic Echoes, poesia italiana, AI, musica moderna, fusione poetica"/>
                      <link
                          href="https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700;900&family=Roboto+Mono:wght@300;400;700&display=swap"
                          rel="stylesheet"/>
                      <script type="application/ld+json">
                          {JSON.stringify(schema)}
                      </script>
                  </Helmet>
                  <Header/>
                  <Routes>
                      <Route path="/" element={
                          <>
                              <Hero/>
                              <Experience/>
                              <Album/>
                          </>
                      }/>
                  </Routes>
                  <Footer/>
                  {showPrivacyPolicy && (
                      <PrivacyPolicy onClose={() => setShowPrivacyPolicy(false)}/>
                  )}
                  <CookieConsent
                      location="bottom"
                      buttonText="Accetta"
                      declineButtonText="Rifiuta"
                      cookieName="CookieConsent"
                      style={{background: "#2B373B"}}
                      buttonStyle={{color: "#4e503b", fontSize: "13px"}}
                      declineButtonStyle={{color: "#4e503b", fontSize: "13px"}}
                      expires={150}
                      enableDeclineButton
                      onAccept={handleAccept}
                  >
                      Questo sito web utilizza i cookie per migliorare l'esperienza utente e analizzare il traffico del
                      sito.
                      Cliccando su "Accetta", acconsenti all'uso dei cookie.
                      <button
                          onClick={() => setShowPrivacyPolicy(true)}
                          style={{
                              color: "#ffd42d",
                              marginLeft: "5px",
                              background: "none",
                              border: "none",
                              textDecoration: "underline",
                              cursor: "pointer"
                          }}
                      >
                          Scopri di più
                      </button>
                  </CookieConsent>
                  <div ref={cursorRef} className="custom-cursor"></div>
              </div>
          </Router>
      </HelmetProvider>
  );
}

export default App;