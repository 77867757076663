import React, {useEffect, useRef} from 'react';
import styled, {keyframes} from 'styled-components';

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const gridMove = keyframes`
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 50px 50px;
    }
`;

const HeroSection = styled.section`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-color: #000000;
`;

const GridBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
    background-size: 50px 50px;
    animation: ${gridMove} 5s linear infinite;
`;

const HeroContent = styled.div`
    text-align: center;
    z-index: 2;
    position: relative;
`;

const HeroTitle = styled.h1`
    font-family: 'Arial', sans-serif;
    font-size: 8vw;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: -0.05em;
    margin-bottom: 20px;
    color: #FFFFFF;
    opacity: 0;
    animation: ${fadeIn} 1s forwards;
`;

const HeroSubtitle = styled.p`
    font-size: 1.5rem;
    max-width: 600px;
    margin: 0 auto 40px;
    color: #FFFFFF;
    opacity: 0;
    animation: ${fadeIn} 1s forwards 0.5s;
`;

const CTAButton = styled.a`
    display: inline-block;
    background-color: transparent;
    color: #00FFFF;
    padding: 12px 30px;
    text-decoration: none;
    font-weight: 700;
    font-size: 1.2rem;
    border: 2px solid #00FFFF;
    border-radius: 30px;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    text-transform: uppercase;
    opacity: 0;
    animation: ${fadeIn} 1s forwards 1s;

    &:hover {
        background-color: #00FFFF;
        color: #000000;
    }
`;

const FloatingElement = styled.div`
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    pointer-events: none;
`;

function Hero() {
    const heroRef = useRef(null);

    useEffect(() => {
        const hero = heroRef.current;
        const elements = [];
        const elementCount = 20;

        for (let i = 0; i < elementCount; i++) {
            const element = document.createElement('div');
            element.className = FloatingElement.styledComponentId;
            element.style.left = `${Math.random() * 100}%`;
            element.style.top = `${Math.random() * 100}%`;
            hero.appendChild(element);
            elements.push(element);
        }

        const animateElements = () => {
            elements.forEach(element => {
                const speed = 2 + Math.random();
                element.style.transform = `translate(${Math.sin(Date.now() / 1000) * 20}px, ${Math.cos(Date.now() / 1000) * 20}px)`;
                element.style.transition = `transform ${speed}s ease-out`;
            });
            requestAnimationFrame(animateElements);
        };

        animateElements();

        return () => {
            elements.forEach(element => hero.removeChild(element));
        };
    }, []);

    return (
        <HeroSection ref={heroRef}>
            <GridBackground/>
            <HeroContent>
                <HeroTitle>Epic Echoes</HeroTitle>
                <HeroSubtitle>Dove i versi immortali incontrano i beat del futuro</HeroSubtitle>
                <CTAButton href="#experience">Entra nel Vortice</CTAButton>
            </HeroContent>
        </HeroSection>
    );
}

export default Hero;
