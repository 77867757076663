import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import YouTube from 'react-youtube';

const glowAnimation = keyframes`
    0%, 100% {
        box-shadow: 0 0 5px #00FFFF, 0 0 10px #00FFFF, 0 0 15px #00FFFF, 0 0 20px #00FFFF;
    }
    50% {
        box-shadow: 0 0 10px #00FFFF, 0 0 20px #00FFFF, 0 0 30px #00FFFF, 0 0 40px #00FFFF;
    }
`;

const rotateAnimation = keyframes`
    0% {
        transform: rotateX(0deg) rotateY(0deg);
    }
    100% {
        transform: rotateX(360deg) rotateY(360deg);
    }
`;

const AlbumSection = styled.section`
    padding: 100px 0;
    position: relative;
    background-color: rgba(0, 0, 0, 0.8);
`;

const SectionTitle = styled.h2`
    font-family: 'Orbitron', sans-serif;
    font-size: 3rem;
    margin-bottom: 40px;
    text-align: center;
    color: #FFFF00;
    text-transform: uppercase;
`;

const AlbumVisualizer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    position: relative;
    height: 400px;
    perspective: 1000px;
`;

const CubeContainer = styled.div`
    width: 300px;
    height: 300px;
    position: relative;
    transform-style: preserve-3d;
    animation: ${rotateAnimation} 20s linear infinite;
`;

const CubeFace = styled.div`
    position: absolute;
    width: 300px;
    height: 300px;
    background-color: rgba(0, 255, 255, 0.2);
    border: 2px solid #00FFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Orbitron', sans-serif;
    color: #00FFFF;
    font-size: 1.5rem;
    text-align: center;
    padding: 20px;
    word-wrap: break-word;
    animation: ${glowAnimation} 2s infinite;
    backface-visibility: hidden;
`;

const TrackList = styled.div`
    max-width: 800px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
`;

const TrackItem = styled.div`
    background-color: rgba(0, 255, 255, 0.1);
    padding: 15px;
    border-radius: 5px;
    transition: all 0.3s ease;
  cursor: none;

    &:hover {
        transform: scale(1.05);
        background-color: rgba(0, 255, 255, 0.2);
    }
`;

const TrackTitle = styled.h3`
    font-family: 'Orbitron', sans-serif;
    font-size: 1rem;
    color: #00FFFF;
    margin-bottom: 5px;
`;

const TrackDuration = styled.p`
    font-size: 0.9rem;
    color: #FFFFFF;
`;

const StreamingButtons = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 40px;
`;

const StreamingButton = styled.a`
    display: inline-block;
    margin: 10px;
    padding: 10px 20px;
    background-color: #00FFFF;
    color: #000;
    text-decoration: none;
    font-family: 'Orbitron', sans-serif;
    font-weight: bold;
    border-radius: 5px;
    transition: all 0.3s ease;

    &:hover {
        background-color: #FF00FF;
        transform: translateY(-3px);
        box-shadow: 0 5px 15px rgba(255, 0, 255, 0.4);
    }
`;

const PlayerControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const ControlButton = styled.button`
  background: none;
  border: none;
  color: #00FFFF;
  font-size: 24px;
  margin: 0 10px;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #FF00FF;
  }
`;

const VolumeControl = styled.input`
  -webkit-appearance: none;
  width: 100px;
  height: 5px;
  border-radius: 5px;
  background: #00FFFF;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #FF00FF;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #FF00FF;
    cursor: pointer;
  }
`;

function Album() {
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [cubeTexts, setCubeTexts] = useState(['Epic Echoes', 'Trap', 'Poesia', 'AI', 'Fusion', 'Innovation']);
  const [player, setPlayer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(100);

  const tracks = useMemo(() => [
    {id: 1, title: "Inferno, Canto I", duration: "03:41", youtubeId: "LPxmQ6aZKyY"},
    {id: 2, title: "A Zacinto", duration: "02:17", youtubeId: "KN-2YkuuFQE"},
    {id: 3, title: "Il Sabato del Villaggio", duration: "03:57", youtubeId: "K_2FTdOArgw"},
    {id: 4, title: "L'infinito", duration: "02:40", youtubeId: "XXeUgFOlYqA"},
    {id: 5, title: "Il Pianto della Madonna", duration: "03:20", youtubeId: "-scPfBlsLVI"},
    {id: 6, title: "La Sera al Dì di Festa", duration: "04:00", youtubeId: "t1yr9cTqy0E"},
    {id: 7, title: "Il Passero Solitario", duration: "03:59", youtubeId: "hPzfasCqvQ0"},
    {id: 8, title: "Inferno, Canto III", duration: "03:59", youtubeId: "PkmBPPVww9g"},
    {id: 9, title: "Verrà la Morte e Avrà i Tuoi Occhi", duration: "03:44", youtubeId: "dIjQBM-JVMU"},
    {id: 10, title: "Alla Sera", duration: "02:54", youtubeId: "uev5kgxCOnQ"},
    {id: 11, title: "Tanto Gentile e Tanto Onesta Pare", duration: "02:45", youtubeId: "6VZ4rHriB08"},
    {id: 12, title: "X Agosto", duration: "03:14", youtubeId: "dQ3mUfyrI5s"},
    {id: 13, title: "A Silvia", duration: "04:00", youtubeId: "TTV6A9CATRI"},
    {id: 14, title: "Canto Notturno di un Pastore Errante dell'Asia", duration: "03:53", youtubeId: "movW8dJJcFk"},
    {id: 15, title: "Dei Sepolcri", duration: "04:00", youtubeId: "jesEnRRm0o0"},
    {id: 16, title: "La Pioggia nel Pineto", duration: "04:03", youtubeId: "FcvTG8bKXic"},
    {id: 17, title: "Iliade, Proemio", duration: "04:00", youtubeId: "NxO2e7wKwuk"}
  ], []);

  const handleTrackClick = useCallback((track) => {
    setSelectedTrack(track);
    setCubeTexts([track.title, ...tracks.map(t => t.title).filter(t => t !== track.title).slice(0, 5)]);
    if (player) {
      player.loadVideoById(track.youtubeId);
      setIsPlaying(true);
    }
  }, [player, tracks]);

  const handlePlayPause = useCallback(() => {
    if (player) {
      if (isPlaying) {
        player.pauseVideo();
      } else {
        player.playVideo();
      }
      setIsPlaying(!isPlaying);
    }
  }, [player, isPlaying]);

  const handleVolumeChange = useCallback((e) => {
    const newVolume = e.target.value;
    setVolume(newVolume);
    if (player) {
      player.setVolume(newVolume);
    }
  }, [player]);

  const onReady = useCallback((event) => {
    setPlayer(event.target);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!selectedTrack) {
        const randomTracks = tracks.sort(() => 0.5 - Math.random()).slice(0, 6);
        setCubeTexts(randomTracks.map(track => track.title));
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [selectedTrack, tracks]);

  return (
      <AlbumSection id="album">
        <div className="container">
          <SectionTitle>Trap e Poesia: Echi dal Passato Vol. 1</SectionTitle>
          <AlbumVisualizer>
            <CubeContainer>
              <CubeFace style={{transform: 'rotateY(0deg) translateZ(150px)'}}>{cubeTexts[0]}</CubeFace>
              <CubeFace style={{transform: 'rotateY(90deg) translateZ(150px)'}}>{cubeTexts[1]}</CubeFace>
              <CubeFace style={{transform: 'rotateY(180deg) translateZ(150px)'}}>{cubeTexts[2]}</CubeFace>
              <CubeFace style={{transform: 'rotateY(-90deg) translateZ(150px)'}}>{cubeTexts[3]}</CubeFace>
              <CubeFace style={{transform: 'rotateX(90deg) translateZ(150px)'}}>{cubeTexts[4]}</CubeFace>
              <CubeFace style={{transform: 'rotateX(-90deg) translateZ(150px)'}}>{cubeTexts[5]}</CubeFace>
            </CubeContainer>
          </AlbumVisualizer>
          <TrackList>
            {tracks.map(track => (
                <TrackItem
                    key={track.id}
                    onClick={() => handleTrackClick(track)}
                    role="button"
                    tabIndex={0}
                >
                  <TrackTitle>{track.title}</TrackTitle>
                  <TrackDuration>{track.duration}</TrackDuration>
                </TrackItem>
            ))}
          </TrackList>
          <PlayerControls>
            <ControlButton onClick={handlePlayPause}>
              {isPlaying ? '⏸️' : '▶️'}
            </ControlButton>
            <VolumeControl
                type="range"
                min="0"
                max="100"
                value={volume}
                onChange={handleVolumeChange}
            />
          </PlayerControls>
          <YouTube
              videoId={selectedTrack?.youtubeId}
              opts={{
                height: '0',
                width: '0',
                playerVars: {
                  autoplay: 1,
                  controls: 0,
                  disablekb: 1,
                  fs: 0,
                  iv_load_policy: 3,
                  modestbranding: 1,
                  rel: 0,
                },
              }}
              onReady={onReady}
          />
          <StreamingButtons>
            <StreamingButton href="https://open.spotify.com/artist/41Tdqhv9xoJyogykSFglrb" target="_blank"
                             rel="noopener noreferrer">Ascolta su Spotify</StreamingButton>
            <StreamingButton href="https://music.youtube.com/playlist?list=OLAK5uy_kEnhebbD_3oQNlcRrp-fUvwHMiQl4z-AM"
                             target="_blank" rel="noopener noreferrer">Ascolta su YouTube
              Music</StreamingButton>
            <StreamingButton href="https://music.amazon.it/artists/B0D83YBPJW/epic-echoes" target="_blank"
                             rel="noopener noreferrer">Ascolta su Amazon
              Music</StreamingButton>
          </StreamingButtons>
        </div>
      </AlbumSection>
  );
}

export default Album;